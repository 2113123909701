import "./navbar.scss";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CartModal from "../CartModal/CartModal";
import logo from "../../shared/images/cloud251Logo.webp";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";

const Navbar = () => {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [open, setOpen] = useState(false);
  const [navIsOpen, setNavIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [cartItemsCount, setCartItemsCount] = useState(0);
  const [isCartModalOpen, setIsCartModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const checkLoggedInUser = () => {
      const loggedInUser = JSON.parse(sessionStorage.getItem("loggedIn-user"));
      setIsUserLoggedIn(!!loggedInUser);
    };

    checkLoggedInUser();

    const handleStorageChange = () => {
      checkLoggedInUser();
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  //   if (loggedInUser) {
  //     SetIsUserLoggedIn(true);
  //   } else {
  //     SetIsUserLoggedIn(false);
  //   }
  // }, [sessionStorage.getItem("loggedIn-user")]);
  // useEffect(() => {
  //   const cartItems = JSON.parse(localStorage.getItem("purchaseItems")) || [];
  //   setCartItemsCount(cartItems.length);
  // }, []);

  useEffect(() => {
    const handleCartItemsCount = () => {
      const cartItems = JSON.parse(localStorage.getItem("purchaseItems")) || [];
      setCartItemsCount(cartItems.length);
    };

    window.addEventListener("storage", handleCartItemsCount);

    handleCartItemsCount();

    return () => {
      window.removeEventListener("storage", handleCartItemsCount);
    };
  }, []);

  const handleNav = () => {
    setNavIsOpen(!navIsOpen);
  };

  const handleCartIconClick = () => {
    setIsCartModalOpen(true);
    setNavIsOpen(false);
    setOpen(false);
  };

  const closeCartModal = () => {
    setIsCartModalOpen(false);
  };

  const handleLogOut = () => {
    setOpen(false);
    sessionStorage.removeItem("loggedIn-user");
    window.dispatchEvent(new Event("storage"));
    navigate("/");
    setNavIsOpen(false);
  };

  return (
    <div className="flex font-Inter justify-center">
      {/* This is the black overlay */}
      {navIsOpen && (
        <div
          onClick={() => {
            setNavIsOpen(!navIsOpen);
            setOpen(false);
          }}
          className="fixed top-0 left-0 w-full h-screen bg-black/50 z-10"
        ></div>
      )}
      {open && (
        <div
          onClick={() => {
            setNavIsOpen(false);
            setOpen(false);
          }}
          className="fixed top-0 left-0 w-full h-screen bg-black/5 z-10"
        ></div>
      )}
      <nav
        className={`fixed w-full lg:w-5/6 lg:top-[2em] lg:rounded-full bg-black/5 backdrop-blur-sm flex justify-center items-center p-3 px-5 xl:px-[3rem] shadow-md z-10`}
      >
        {/* This is the logo */}
        <div className="max-w-[4em] self-center">
          <img className="max-w-full" src={logo} alt=" Company Logo" />
        </div>
        {/* This is the nav that's displayed when the screen is greater than mid */}
      </nav>
      {/* <CartModal open={isCartModalOpen} onClose={closeCartModal} /> */}
    </div>
  );
};

export default Navbar;
